/*
 * Copyright (C) 2016 BBC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 * http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

* {
	padding: 0;
	margin: 0;
}

html {
	height: 100%;
}

body {
	height: 100%;
	width: 100%;
	font-family: "Gill Sans MT Regular", "Gill Sans MT", "Gill Sans Regular", "Gill Sans", "Arial";
	background-color: black;
}

#root {
	height: 100%;
	overflow-x: hidden;
}

/*
====== Containers/Main Layout ======
*/

#container-parent {
	height: calc(100vh - 92px);
}

@media screen and (min-width: 768px) {
	#container-parent {
		height: calc(100vh - 56px);
	}
}

/* Represents the whole table, which contains 2 container rows */
.container-table {
	height: 100%;
	display: table;
	width: 100%;
	margin: 0;
	padding: 0;
	position: relative;
	color: #ffffff;
}

/* Represents a single row, either Theme or Decade */
.container-row {
	height: 50%;
	width: 100%;
	display: table-row;
	cursor: pointer;
}

.container-light {
	background-color: #555555 !important;
}

.container-dark {
	background-color: #000;
}

/* Represents text inside the row, i.e. the title "Theme" */
.container-content {
	display: table-cell;
	text-align: center;
	vertical-align: middle;
}

.container-content:hover {
	text-decoration-color: white;
}

@media screen and (min-width: 768px) {
	.container-full-height {
		height: calc(100% - 56px);
	}
}

/*
====== Selection Buttons ======
*/

/* Overrides the style for all buttons first */
.btn {
	padding: 14px 24px;
	border: 0 none;
	border-radius: 0;
	font-family: "Gill Sans MT Regular", "Gill Sans MT", "Gill Sans Regular", "Gill Sans", "Arial";
}

.btn:focus,
.btn:active:focus,
.btn.active:focus {
	outline: 0 none; /* Remove the outline on press */
}

/* Now override the style for the default button class, used for all selection buttons on the container page*/
.btn-default {
	background: #555555;
	color: #ffffff;
	text-shadow: none;
	border: 2px solid;
	border-color: #333333;
}

.btn-default:hover,
.btn-default:focus,
.btn-default:active,
.btn-default.active,
.open > .dropdown-toggle.btn-default {
	background: #757575;
	color: #ffffff;
	border-color: #333333;
}

.btn-default:active,
.btn-default.active {
	background: #777777;
	color: #ffffff;
	box-shadow: none;
	border-color: #333333;
}

/* Add a back button style that's plain */
.btn-back,
.btn-back:active,
.btn-back.active,
.open > .dropdown-toggle.btn-back {
	background: #1d1d1d;
	border-color: #1c1c1c;
	font-size: 3.5vh;
}

.btn-back:hover,
.btn-back:focus {
	background: #757575;
}
