/*
====== Decade Selection ======
*/

.decade-bg-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #555555;
  z-index: -1;
}