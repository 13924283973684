#container-theme,
#container-split-theme,
.theme-bg {
  background-color: #2f2f2f;

  &:hover,
  &:focus {
    background-color: #1f1f1f;
  }
}

#container-decade,
#container-split-decade,
.decade-bg {
  background-color: #555555;

  &:hover,
  &:focus {
    background-color: #494949;
  }
}

/*Creates a white circle, 50x50 and positions the center of it where top-left would be*/
#table-circle {
  width: 70px;
  height: 70px;
  -webkit-border-radius: 35px;
  -moz-border-radius: 35px;
  border-radius: 35px;
  background-color: #ffffff;
  margin-left: -35px;
  margin-top: -35px;
}

/*Overlays the contents of this div over the parent, with the top-left in the center*/
.table-overlay {
  position: absolute;
  left: 50%;
  top: 50%;
  z-index: 10;
}

/*Text to be used in the overlay. The margin-top could be better defined I think?*/
.table-overlay-text {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  margin-top: 0px;
  font-size: 28px;
  color: #000000;
}

/** Styling for the main titles **/
.section-title {
  font-size: 72px;
  color: #ffffff;
}
