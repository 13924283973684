/*
====== Theme Selection ======
*/

.theme-bg-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #2f2f2f;
  z-index: -1;
}

.page-title {
  color: #ffffff;
  text-align: center;
  z-index: 10;
  padding-top: 30px;
}

h2 {
  margin-top: 50px;
  margin-bottom: 20px;
  width: 100%;
}

/* Classes for theme and decade command buttons, if needed */
.btn-theme,
.btn-decade {
  font-size: 3.5vh !important;
  margin-top: 26px;
  width: 100%;
  background-color: #555555;
  text-align: center;
  padding: 15px 0;
  border: 2px solid #333333;

  &:hover,
  &:focus {
    background-color: #757575;
  }
}

.btn-theme-link {
  color: white;

  &:hover,
  &:focus {
    color: white;
    text-decoration: none;
  }
}
