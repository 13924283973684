/*
====== Intro Modal ======
*/

.modal-header {
  border-bottom-style: none !important;
}

.modal-title {
  font-size: 26px;
  margin: 0 0 0 auto;
}

.modal-content {
  border-bottom-style: none !important;
  font-size: 26px;
}

.modal-footer {
  margin: 0 auto;
  width: 100%;
  border-top-style: none !important;
  .footer-center {
    justify-content: center;
    button {
      font-size: 20px;
    }
  }
  .footer-side {
    width: 43%;
    text-align: right;
    padding-bottom: 14px;
    padding-right: 10px;
    justify-content: right;
    a {
      color: grey;
      font-size: 16px;
    }
  }
}

.modal-body {
  small {
    font-size: medium;
  }
}
