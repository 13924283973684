/*
====== Not Found ======
*/

.not-found-bg {
  width: 100%;
  height: 100%;
  background-color: #2f2f2f;
}

.not-found-text {
  color: #ffffff;
  text-align: center;
  z-index: 10;
  padding-top: 40px;
  padding-left: 40px;
  padding-bottom: 40px;
}

.not-found-link a {
  color: #999999;
  &:hover, &:focus {
    color: #ffffff;
  }
}
  