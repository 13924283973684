/*
====== Navigation Bar ======
*/

/* Override bootstrap nav bar, remove the margin so the containers all sit flush to it */
.navbar {
  margin-bottom: 0px;
  flex-wrap: wrap !important;
  flex-flow: column !important;
  justify-content: space-between !important;
  align-items: flex-start !important;
}

@media screen and (min-width: 768px) {
  .navbar {
    flex-flow: row !important;
    align-items: center;
  }
}

/* This is to change the colour of the nav bar */
.navbar-inverse {
  background-image: -webkit-linear-gradient(top, #000 0, #111 100%);
  background-image: -o-linear-gradient(top, #000 0, #111 100%);
  background-image: -webkit-gradient(linear, left top, left bottom, from(#000), to(#111));
  background-image: linear-gradient(to bottom, #000 0, #111 100%);
}

.navbar-header {
  color: rgb(153, 153, 153) !important;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 10px;
  h1 {
    font-size: 24px;
    font-weight: 300;
  }
}

@media screen and (min-width: 768px) {
  .navbar-header {
    margin-bottom: 0;
  }
}

.navbar-header:hover {
  color: white !important;
}

.navbar-brand-logo {
  height: 100%;
  display: inline-block;
  margin-top: -3px;
  filter: invert(100%) !important;
}

.navbar-start-button {
  background-color: #ffffff;
  color: #000000;
  padding: 0 !important;
  min-width: 130px;
  height: 40px;
  display: flex !important;
  flex-direction: column;
  justify-content: center;
  font-size: 24px !important;

  &:hover,
  &:focus {
    opacity: 0.75;
  }
}

@media screen and (min-width: 768px) {
  .navbar-start-button {
    min-width: 180px !important;
  }
}

.navbar-right {
  padding-right: 20px;
}

.navbar-overflow-btn {
  color: white;
}

.main-nav {
  .nav-item {
    button {
      color: rgb(153, 153, 153);
      font-size: 16px;
      padding-top: 0;
      padding-bottom: 0;
    }
  }
}

@media screen and (min-width: 768px) {
  .main-nav {
    .nav-item {
      button {
        font-size: 20px;
      }
    }
  }
}

@media screen and (min-width: 1024px) {
  .main-nav {
    .nav-item {
      button {
        font-size: 24px;
      }
    }
  }
}

.main-nav {
  .nav-item {
    button:first-child {
      padding-left: 0;
      padding-right: 16px;
    }
  }
}

.main-nav {
  .nav-item {
    button:hover {
      color: white !important;
      text-decoration: none;
    }
  }
}
