/*
======= Slide Carousel =======
*/

.loading-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.loading-icon {
  svg {
    color: white;
    fill: white;
    animation-name: loader-button-rotate;
    animation-duration: 1s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
  }
}

@keyframes loader-button-rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.asset-download-button {
  position: fixed;
  top: 100px;
  right: 120px;
  border: none;
  background-color: transparent;
  color: white;
  z-index: 1001;
}

.asset-info-button {
  position: fixed;
  top: 100px;
  right: 30px;
  border: none;
  background-color: transparent;
  color: white;
  z-index: 1001;

  &:hover,
  &:focus {
    opacity: 0.75;
  }
}

@media screen and (min-width: 768px) {
  .asset-download-button,
  .asset-info-button {
    top: 80px;
  }
}

.slide-image {
  width: 100%;
  max-height: calc(100vh - 235px);
  object-fit: contain;
}

.main-carousel {
  height: calc(80vh - 92px);
}

@media screen and (min-width: 768px) {
  .main-carousel {
    height: calc(80vh - 56px);
  }
}

.thumbnail-carousel {
  margin-left: 4vw;
  margin-right: 4vw;
}

@media screen and (min-width: 768px) {
  .thumbnail-carousel {
    height: 20vh;
  }
}

@media screen and (min-width: 1024px) {
  .thumbnail-carousel {
    margin-left: 10vw;
    margin-right: 10vw;
  }
}

@media screen and (min-width: 1280px) {
  .thumbnail-carousel {
    margin-left: 17vw;
    margin-right: 17vw;
  }
}

.center-carousel {
  justify-content: center;
}

.carousel-thumb-button {
  border: none;
  background-color: transparent;
  margin: 0 5px;
  z-index: 300;

  &:hover,
  &:focus {
    transform: scale(1.1);
  }
}

.selector-image {
  height: 80px;
  width: 100%;
  object-fit: cover;
}

@media screen and (min-width: 375px) {
  .selector-image {
    height: 120px;
  }
}

@media screen and (min-width: 768px) {
  .carousel-thumb-button {
    margin: 0 10px;
  }

  .selector-image {
    height: 140px;
  }
}

@media screen and (min-width: 1024px) {
  .carousel-thumb-button {
    margin: 0 10px;
  }

  .selector-image {
    height: 140px;
    max-width: 200px;
  }
}

@media screen and (min-width: 1280px) {
  .carousel-thumb-button {
    margin: 0 6px;
    padding: 0 -40px;
  }
}


.active-image {
  border: 2px solid white;
}

.main-carousel {
  .react-multiple-carousel__arrow {
    border-radius: 0;
    top: 50%;
    padding: 20px;
  }
}

@media screen and (min-width: 1280px) {
  .main-carousel {
    .react-multiple-carousel__arrow {
      padding: 0 20px;
    }
  }

  .main-carousel {
    .react-multiple-carousel__arrow {
      top: 0;
      height: 100%;
    }
  }
}

.main-carousel {
  .react-multiple-carousel__arrow {
    svg {
      opacity: 0.5;
      width: 32px !important;
      height: 32px !important;
    }
  }
}

.main-carousel {
  .react-multiple-carousel__arrow {
    &:focus,
    &:hover {
      svg {
        opacity: 1;
      }
    }
  }
}

.main-carousel {
  .react-multiple-carousel__arrow--left {
    left: 0;
  }
}

.main-carousel {
  .react-multiple-carousel__arrow--right {
    right: 0;
  }
}

/* Applies to images only */
.main-carousel.images-carousel {
  ul {
    .react-multi-carousel-item {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }
}

@media screen and (min-width: 768px) {
  .main-carousel {
    .react-multiple-carousel__arrow {
      svg {
        width: 60px !important;
        height: 60px !important;
      }
    }
  }
}

.react-multiple-carousel__arrow--left::before,
.react-multiple-carousel__arrow--right::before {
  content: "";
}

.react-multiple-carousel__arrow--left,
.react-multiple-carousel__arrow--right {
  svg {
    fill: white;
    color: white;
  }
}

.react-multiple-carousel__arrow {
  span {
    color: white;
    margin: 0 10px;
    font-size: 36px;
  }
}

.thumbnail-carousel-button-container {
  position: absolute;
  left: 0;
  z-index: 100;
  width: 100%;
  height: 1px;
}

.thumbnail-carousel-button {
  border: none;
  background-color: black;
  color: white;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  opacity: 0.5;
  padding: 5px;

  &:hover,
  &:focus {
    opacity: 1;
  }
}

.thumbnail-carousel-button.button-left {
  margin-left: -3vw;
  margin-top: 3vh;
  float: left;
}

.thumbnail-carousel-button.button-right {
  margin-right: -3vw;
  margin-top: 3vh;
  float: right;
}

.thumbnail-carousel-button.button-more {
  margin-right: -3vw;
  margin-top: 3vh;
  float: right;
}

.thumbnail-carousel-button {
  span {
    font-size: 32px;
    margin: 0 10px;
    display: none;
  }
}

@media screen and (min-width: 768px) {
  .thumbnail-carousel-button {
    span {
      display: block;
    }
  }

  .thumbnail-carousel-button.button-left {
    margin-left: -5vw;
    margin-top: 8vh;
  }
  
  .thumbnail-carousel-button.button-right {
    margin-right: -5vw;
    margin-top: 8vh;
  }
  
  .thumbnail-carousel-button.button-more {
    margin-right: -8vw;
    margin-top: 8vh;
  }  
}

@media screen and (min-width: 1024px) {
  .thumbnail-carousel-button.button-left {
    margin-right: 0;
  }

  .thumbnail-carousel-button.button-right {
    margin-left: 0;
  }
}

.flex-center {
  display: flex;
  align-items: center;
}

.justify-start {
  justify-content: flex-start;
}

.justify-end {
  justify-content: flex-end;
}
