/* 
====== SMP Player ======
*/

.smp-container {
  max-width: 1024px;
  margin: 0 auto;
  position: relative;
}

.smp-playback-controls {
  position: absolute;
  z-index: 1000;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;

  .smp-audio-overlay {
    background-color: rgba(0, 0, 0, 0.4);
    position: absolute;
    z-index: 1001;
    width: 100%;
    height: 100%;
  }
  img {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 1;
  }
  button {
    z-index: 1002;
    border: none;
    background-color: transparent;
  }

  div {
    svg {
      width: 80px !important;
      height: 80px !important;

      &:hover,
      &:focus {
        opacity: 0.7;
      }
    }
  }
  @media screen and (min-width: 768px) {
    div {
      svg {
        width: 140px !important;
        height: 140px !important;
      }
    }
  }
  @media screen and (max-height: 480px) {
    div {
      svg {
        width: 80px !important;
        height: 80px !important;
      }
    }
  }  
}

.smp-overlay-button {
  background-color: transparent;
  position: absolute;
  width: 100%;
  height: 75%;
  top: 0;
  left: 0;
  z-index: 1020;
  border: none;
  align-items: unset;
  
  .play-control-button {
    margin-top: 27%;
  }
  @media screen and (max-height: 1024px) {
    .play-control-button {
      margin-top: 20%;
    }
  }
  @media screen and (max-height: 768px) {
    .play-control-button {
      margin-top: 15%;
    }
  }
  @media screen and (max-height: 640px) {
    .play-control-button {
      margin-top: 5%;
    }
  }
  @media screen and (max-height: 320px) {
    .play-control-button {
      margin-top: 0;
    }
  }

  &.-audio {
    height: 100%;
  }
  &:focus {
    outline: none;
  }
  span {
    display: none;
  }
}

.smp-player {
  background: black;
  height: calc(50vh - 56px);
  max-height: 1024px;
}
@media screen and (min-width: 768px) {
  .smp-player {
    height: calc(80vh - 56px);
  }
}
